.deck {
  position: absolute;
  left: 60rem;
  width: 45rem;
  height: 50rem;
  will-change: transform;
  display: flex;
  align-items: center;
  justify-content: center;
}

.deck__algo {
  background-color: white;
  background-size: auto 85%;
  background-repeat: no-repeat;
  background-position: center center;
  width: 30rem;
  height: 45rem;
  will-change: transform;
  border-radius: 10px;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

@media(max-width: 1050px) {
  .deck {
    width: 40rem;
    height: 45rem;
  }
}

@media(max-width: 1000px) {
  .deck {
    left: 55rem;
  }
  .header-vendas__texto {
    width: 50rem;
  }
}

@media(max-width: 950px) {
  .deck {
    left: 55rem;
    width: 35rem;
    height: 40rem;
  }
  .deck__algo {
    width: 25rem;
    height: 35rem;
  }
  .header-vendas__texto {
    width: 50rem;
  }
}

@media(max-width: 730px) {
  .deck {
    left: 45rem;
  }
  .header-vendas__texto {
    width: 45rem;
  }
}

@media(max-width: 650px) {
  .deck {
    left: 45rem;
    top: 20rem;
    width: 27.5rem;
    height: 30rem;
  }
  .deck__algo {
    width: 22.5rem;
    height: 30rem;
  }
  .header-vendas__texto {
    width: 40rem;
  }
}

@media(max-width: 440px) {
  .header-vendas {
    height: 120rem;
  }
  .deck {
    left: 15rem;
    top: 80rem;
    width: 40rem;
    height: 30rem;
  }
  .deck__algo {
    width: 30rem;
    height: 45rem;
  }
  .header-vendas__texto {
    position: relative;
    top: 5rem;
    width: 55rem;
    margin: 0 auto;
    text-align: center;
  }
  .header-vendas__logo {
    width: 25rem;
  }
}

@media(max-width: 380px) {
  .deck {
    left: 13rem;
    top: 80rem;
  }
  .header-vendas__texto {
    width: 50rem;
  }
  .apple-header-vendas__texto {
    width: auto;
  }
}

@media(max-width: 350px) {
  .deck {
    left: 10rem;
  }
}

@media(max-width: 330px) {
  .deck {
    left: 7rem;
  }
}
