.aus-bar {
  display: inline-block;
  width: auto;
  height: 4.5rem;
  border: .1rem solid black;
  border-radius: 1rem;
  background-color: gray;
  text-align: center;
  box-shadow: 0px .3rem .5rem black;
  margin-top: 7%;
}

.aus-bar__text {
  position: relative;
  margin: 0;
  padding: 0 1rem;
  white-space: nowrap;
  font-size: 1.6rem;
  line-height: 4.5rem;
}

@media(max-width: 900px) {
  .aus-bar__text {
    font-size: 1.7rem;
  }
}

@media(max-width: 800px) {
  .aus-bar__text {
    font-size: 1.8rem;
  }
}

@media(max-width: 600px) {
  .aus-bar__text {
    font-size: 2rem;
  }
}
