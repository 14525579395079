.tradutor {
  position: fixed;
  bottom: 4rem;
  Left: 4rem;
  z-index: 90;
}

.tradutor__selector {
  font-size: 1.6rem;
  background-color: green;
  border: none;
  color: white;
  font-weight: 650;
  padding: 1rem;
  border-radius: 1rem;
  appearance: none;
  cursor: pointer;
  outline: none;
  box-shadow: 0 2px 3px 2px black;
  text-transform: capitalize;
}

.tradutor__selector * {
  font-weight: 500;
  text-transform: capitalize;
}

@media(max-width: 900px) {
  .tradutor__selector {
    font-size: 1.8rem;
  }
}

@media(max-width: 700px) {
  .tradutor__selector {
    font-size: 2rem;
  }
}

@media(max-width: 600px) {
  .tradutor__selector {
    font-size: 2.4rem;
  }
}

@media(max-width: 400px) {
  .tradutor__selector {
    font-size: 2.6rem;
  }
}
