.emotion-choice {
  margin: 0 auto;
  width: 80%;
  height: 9%;
  background-color: gray;
  border-radius: 1rem;
  text-transform: uppercase;
  color: white;
}

.emotion-choice:hover {
  transform: translateX(-50%) translateY(-.1rem);
  cursor: pointer;
  box-shadow: 0px .3rem .5rem black;
}

.emotion-choice:active {
  transform: translateX(-50%) translateY(.05rem);
  box-shadow: 0px .2rem .5rem black;
}

.emotion-choice__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 1.8rem;
}

@media(max-width: 600px) {
  .emotion-choice__text {
    font-size: 2rem;
  }
}

@media(max-width: 500px) {
  .emotion-choice__text {
    font-size: 2.5rem;
  }
}
