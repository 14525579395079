.ferramentas {
  position: relative;
  margin: 3rem auto 6rem auto;
  width: 95vw;
  height: auto;
}

.ferramentas__titule {
  color: gray;
  font-size: 2.75rem;
  width: 100%;
  text-align: center;
}

.ferramentas__grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 4rem;
  margin: 0 auto;
}

@media (max-width: 580px) {
  .ferramentas__grid {
    grid-template-columns: 1fr;
  }
}

.ferramentas__reconhecimento {
  perspective: 150rem;
  -moz-perspective: 150rem;
  height: 45rem;
  width: 25rem;
  position: relative;
  margin: 0 auto;
}

.ferramentas__reconhecimento-frente {
  height: 45rem;
  width: 25rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  background-color: #E6E6E6;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.ferramentas__reconhecimento-frente__text {
  text-align: center;
  position: absolute;
  top: 40%;
}

.ferramentas__reconhecimento-frente__text__titule {
  font-size: 5rem;
  background-color: orange;
  position: relative;
  bottom: 5rem;
  left: 4rem;
  z-index: 10;
}

.ferramentas__reconhecimento-frente__text__sub-titule {
  font-size: 2rem;
}

.ferramentas__reconhecimento-frente__imagem {
  width: auto;
  height: 18rem;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

.ferramentas__reconhecimento-frente__imagem-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18rem;
  background: linear-gradient(315deg, rgba(36, 19, 0, 0.8) 0%, rgba(121, 67, 9, 0.8) 35%, rgba(255, 124, 0, 0.8) 100%);
  clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
  opacity: 60%;
  z-index: 8;
}

.ferramentas__reconhecimento-tras {
  height: 45rem;
  width: 25rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.8);
  position: relative;
  top: 0;
  left: 0;
  transition: all 1s;
  background: linear-gradient(315deg, rgba(36, 19, 0, 0.8) 0%, rgba(121, 67, 9, 0.8) 35%, rgba(255, 124, 0, 0.8) 100%);
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border-radius: 10px;
  text-align: center;
  line-height: 45rem;
}

.ferramentas__reconhecimento-tras__titule {
  margin: 0;
  font-size: 4rem;
  color: white;
  font-weight: lighter;
}

.ferramentas__reconhecimento:hover .ferramentas__reconhecimento-frente {
  transform: rotateY(180deg);
}

.ferramentas__reconhecimento:hover .ferramentas__reconhecimento-tras {
  transform: rotateY(360deg);
}

.ferramentas__treino {
  perspective: 150rem;
  -moz-perspective: 150rem;
  height: 45rem;
  width: 25rem;
  margin: 0 auto;
  position: relative;
}

.ferramentas__treino-frente {
  height: 45rem;
  width: 25rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  background-color: #E6E6E6;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.ferramentas__treino-frente__text {
  text-align: center;
  position: absolute;
  top: 40%;
}

.ferramentas__treino-frente__text__titule {
  font-size: 5rem;
  background-color: green;
  position: relative;
  bottom: 5rem;
  left: 4rem;
  z-index: 10;
}

.ferramentas__treino-frente__text__sub-titule {
  font-size: 2rem;
}

.ferramentas__treino-frente__imagem {
  width: auto;
  height: 18rem;
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

.ferramentas__treino-frente__imagem-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18rem;
  background: linear-gradient(315deg, rgba(3, 36, 0, 0.8) 0%, rgba(19, 121, 9, 0.8) 35%, rgba(86, 255, 0, 0.8) 100%);
  clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
  opacity: 60%;
  z-index: 8;
}

.ferramentas__treino-tras {
  height: 45rem;
  width: 25rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  background: linear-gradient(315deg, rgba(3, 36, 0, 0.8) 0%, rgba(19, 121, 9, 0.8) 35%, rgba(86, 255, 0, 0.8) 100%);
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border-radius: 10px;
  text-align: center;
  line-height: 45rem;
}

.ferramentas__treino-tras__titule {
  margin: 0;
  font-size: 4rem;
  color: white;
  font-weight: lighter;
}

.ferramentas__treino:hover .ferramentas__treino-frente {
  transform: rotateY(180deg);
}

.ferramentas__treino:hover .ferramentas__treino-tras {
  transform: rotateY(360deg);
}

.ferramentas__emocoes {
  perspective: 150rem;
  -moz-perspective: 150rem;
  height: 45rem;
  width: 25rem;
  margin: 0 auto;
  position: relative;
}

.ferramentas__emocoes-frente {
  height: 45rem;
  width: 25rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  background-color: #E6E6E6;
  backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.ferramentas__emocoes-frente__text {
  text-align: center;
  position: absolute;
  top: 40%;
}

.ferramentas__emocoes-frente__text__titule {
  font-size: 5rem;
  background-color: blue;
  position: relative;
  bottom: 5rem;
  left: 0rem;
  z-index: 10;
}

.ferramentas__emocoes-frente__text__sub-titule {
  font-size: 2rem;
}

.ferramentas__emocoes-frente__imagem {
  width: auto;
  height: 18rem;
  transform: translateX(0%);
  clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
}

.ferramentas__emocoes-frente__imagem-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 18rem;
  background: linear-gradient(315deg, rgba(2, 0, 36, 0.8) 0%, rgba(9, 9, 121, 0.8) 35%, rgba(0, 212, 255, 0.8) 100%);
  clip-path: polygon(0 0, 100% 0, 100% 88%, 0 100%);
  opacity: 60%;
  z-index: 8;
}

.ferramentas__emocoes-tras {
  position: relative;
  height: 45rem;
  width: 25rem;
  box-shadow: 0 1px 4px 2px rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  transition: all 1s;
  background: linear-gradient(315deg, rgba(2, 0, 36, 0.8) 0%, rgba(9, 9, 121, 0.8) 35%, rgba(0, 212, 255, 0.8) 100%);
  backface-visibility: hidden;
  transform: rotateY(180deg);
  border-radius: 10px;
  text-align: center;
}

.ferramentas__emocoes-tras__botao {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  height: 7.5rem;
  width: 80%;
  line-height: 7.5rem;
  background-color: #B77600;
  border-radius: 2rem;
  font-size: 3rem;
  font-weight: lighter;
  cursor: pointer;
}

.ferramentas__emocoes:hover .ferramentas__emocoes-frente {
  transform: rotateY(180deg);
}

.ferramentas__emocoes:hover .ferramentas__emocoes-tras {
  transform: rotateY(360deg);
}
