.barra-de-login {
  background-color: white;
  position: fixed;
  top: 5.75rem;
  right: 12.5rem;
  z-index: 200;
  font-size: 1.5rem;
  padding: .75rem;
  border-radius: 10rem;
  box-shadow: 0 2px 3px 2px black;
}

.barra-de-login__login {
  margin: 0;
  margin-right: .5rem;
  cursor: pointer;
  padding: .75rem;
}

.barra-de-login__cadastro {
  margin: 0;
  cursor: pointer;
  padding: .75rem;
}

.barra-de-login__logout {
  margin: 0;
  cursor: pointer;
  padding: .75rem;
}

@media(max-width: 900px) {
  .barra-de-login {
    font-size: 1.75rem;
  }
}

@media(max-width: 600px) {
  .barra-de-login {
    font-size: 2rem;
  }
}
