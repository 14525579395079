.menu-option {
  display: inline-block;
  position: relative;
  top: 1.4rem;
  width: 26%;
  height: 60%;
  background-color: gray;
  text-align: center;
  border-radius: 1rem;
}

.menu-option__text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  color: white;
  font-size: 1.6rem;
}

.menu-option:hover {
  top: 1.3rem;
  cursor: pointer;
  box-shadow: 0px .3rem .5rem black;
}

.menu-option:active {
  position: relative;
  top: 1.45rem;
  box-shadow: 0px .2rem .5rem black;
}

@media(max-width: 700px) {
  .menu-option__text {
    font-size: 2rem;
  }
}

@media(max-width: 500px) {
  .menu-option__text {
    font-size: 2.25rem;
  }
}
