.heading {
  position: relative;
  margin: 0 auto;
  height: 63.5rem;
  width: 95vw;
  text-align: center;
}

.heading__image {
  margin: 0 auto;
  height: 63.5rem;
  width: 100%;
}

.heading__fundo {
  height: 63.5rem;
  width: 95vw;
  clip-path: polygon(0 0, 0 100%, 100% 55rem, 100% 0);
  z-index: -10;
  background-image: url('./../images/faces-background.png');
  background-position: center;
  background-size: 100%;
}

@media(max-width: 700px) {
  .heading__fundo {
    background-image: url('./../images/faces-background-25.png');
  }
}

.heading__background {
  height: 63.5rem;
  width: 100%;
  background-image: linear-gradient(315deg, rgba(2, 0, 36, 0.8) 0%, rgba(9, 9, 121, 0.8) 35%, rgba(0, 212, 255, 0.8) 100%);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  clip-path: polygon(0 0, 0 100%, 100% 55.1rem, 100% 0);
  padding-top: 2px;
  padding-bottom: 0;
}

.heading__primary-text {
  position: absolute;
  width: 100%;
  top: 43.5%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
}

.heading__titule {
  font-size: 5.5rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: lighter;
  animation-name: h1-do-heading;
  animation-duration: 2s;
  animation-delay: 1s;
  animation-fill-mode: backwards;
}

.heading__sub-titule {
  margin-top: .5rem;
  font-size: 2.25rem;
  font-weight: 300;
  animation-name: h1-do-heading;
  animation-duration: 2s;
  animation-delay: 1.5s;
  animation-fill-mode: backwards;
}

.heading__button {
  top: 140%;
  animation-name: h1-do-heading;
  animation-duration: 2s;
  animation-delay: 2.5s;
  animation-fill-mode: backwards;
}

@media only screen and (max-width: 37.5em) {
  .heading__button {
    top: 100%;
  }
}

.btn {
  display: block;
  margin: 2rem auto;
  width: 15rem;
  height: 4rem;
  background-color: white;
  border-radius: 100px;
  color: black;
  text-decoration: none;
  text-align: center;
  line-height: 4rem;
  font-size: 1.75rem;
  transition: all .1s;
  box-shadow: 0 2px 3px 2px rgba(0, 0, 0, 0.8);
}

@media only screen and (max-width: 37.5em) {
  .btn {
    width: 20rem;
    height: 6rem;
    font-size: 2.275rem;
    line-height: 6rem;
  }
}

.btn:hover {
  transform: translateY(-5%);
  box-shadow: 0 5px 3px 2px rgba(0, 0, 0, 0.8);
}

.btn:active {
  transform: translateY(-2%);
  box-shadow: 0 3px 3px 2px rgba(0, 0, 0, 0.8);
}

.heading__logo {
  position: absolute;
  top: 0;
  left: 0;
}

.heading__logo__image {
  height: auto;
  width: 17rem;
  cursor: pointer;
}

@media only screen and (max-width: 37.5em) {
  .heading__logo__image {
    width: 20rem;
  }
}

@media(max-width: 530px) {
  .heading__titule {
    font-size: 4.5rem;
    letter-spacing: 3px;
  }
}

@media(max-width: 377px) {
  .heading__titule {
    font-size: 3.5rem;
    letter-spacing: 2.5px;
  }
  .heading__sub-titule {
    font-size: 2rem;
  }
}

@media(max-width: 800px) {
  .heading {
    height: 115rem;
  }
  .heading__image {
    height: 115rem;
  }
  .heading__fundo {
    height: 115rem;
    clip-path: polygon(0 0, 0 100%, 100% 105rem, 100% 0);
  }
  .heading__background {
    height: 115rem;
    clip-path: polygon(0 0, 0 100%, 100% 105rem, 100% 0);
  }
};

@media(max-width: 900px) {
  .heading {
    height: 95rem;
  }
  .heading__image {
    height: 95rem;
  }
  .heading__fundo {
    height: 95rem;
    clip-path: polygon(0 0, 0 100%, 100% 85rem, 100% 0);
  }
  .heading__background {
    height: 95rem;
    clip-path: polygon(0 0, 0 100%, 100% 85rem, 100% 0);
  }
};
