.menu-bar {
  height: 15%;
  background-color: white;
  text-align: center;
  box-shadow: 0px .3rem .5rem black;
  border-radius: 1rem;
}

.center-box {
  margin-left: 2rem;
  margin-right: 2rem;
}
