.caixa-de-promocao {
  position: relative;
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 2rem;
  padding: 2rem;
  padding-top: 1.3rem;
  padding-bottom: 0rem;
  width: 27rem;
  height: 15rem;
  text-align: left;
}

.caixa-de-promocao__caixa-de-texto {
  margin-left: 3.5rem;
}

.caixa-de-promocao__circle {
  position: absolute;
  left: 0rem;
  top: 0rem;
  background-color: orange;
  height: 5rem;
  width: 5rem;
  border-radius: 50%;
  background-position: center;
  background-size: 65%;
  background-repeat: no-repeat;
}

.caixa-de-promocao__titule {
  margin: 0;
  font-size: 2rem;
  white-space: nowrap;
}

.caixa-de-promocao__text {
  font-size: 1.6rem;
}

.trofeu {
  background-image: url('./../icones/trophy.png');
}

.mentiras {
  background-image: url('./../icones/lie.png');
}

.amigos {
  background-image: url('./../icones/friends.png');
}

.escudo {
  background-image: url('./../icones/body-scan.png');
}

.coracao {
  background-image: url('./../icones/coracao_lupa.png');
}

.vendas {
  background-image: url('./../icones/sales.png');
}

@media(max-width: 900px) {
  .caixa-de-promocao__circle {
    height: 5rem;
    width: 5rem;
  }

  .caixa-de-promocao__titule {
    font-size: 2.2rem;
  }

  .caixa-de-promocao__text {
    font-size: 1.8rem;
  }
}

@media(max-width: 800px) {
  .caixa-de-promocao__titule {
    font-size: 2.3rem;
  }

  .caixa-de-promocao__text {
    font-size: 1.9rem;
  }
}

@media(max-width: 600px) {
  .promover-vendas {
    padding-bottom: 3rem;
  }
  .caixa-de-promocao {
    margin-right: 4rem;
    margin-bottom: 5rem;
  }

  .caixa-de-promocao__titule {
    font-size: 2.4rem;
  }

  .caixa-de-promocao__text {
    font-size: 2.2rem;
  }
}

@media(max-width: 421px) {
  .caixa-de-promocao {
    margin-bottom: 5rem;
    width: 40rem;
  }

  .caixa-de-promocao__circle {
    height: 7rem;
    width: 7rem;
    left: 0rem;
  }

  .caixa-de-promocao__titule {
    font-size: 3rem;
  }

  .caixa-de-promocao__text {
    font-size: 2.5rem;
  }

  .promover-vendas__titule {
    font-size: 6rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .caixa-de-promocao__caixa-de-texto {
    margin-left: 6rem;
  }
}
