.footering {
  margin: 0 auto;
  margin-top: 5rem;
  width: 95vw;
  height: 15rem;
  background-color: #444444;
  text-align: center;
}

.footering__navbar {
  display: inline-block;
  position: relative;
  top: 7.5rem;
  transform: translateY(-50%);
  padding-bottom: 1rem;
  width: auto;
  border-bottom: solid black 1px;
}

.footering__navbar * {
  color: lightgray;
  text-decoration: none;
  list-style: none;
  font-size: 1.60rem;
  font-weight: 300;
  padding: 1rem 1rem;
}

.footering__navbar *:hover {
  color: white;
}

.footering__navbar *:not(.footering__navbar *:last-child) {
  margin-right: .5rem;
}

@media(max-width: 800px) {
  .footering__navbar * {
    font-size: 2rem;
    display: block;
  }
  .footering {
    height: auto;
  }
  .footering__navbar {
    top: 0;
    transform: none;
  }
}

@media(max-width: 600px) {
  .footering__navbar * {
    font-size: 3rem;
  }
}
