.menu {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 80%;
  transform: translate(-50%, -50%);
  width: 40%;
  height: 100%;
}

@media(max-width: 700px) {
  .menu {
    display: block;
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: 60rem;
    margin: 0 auto;
    min-width: 50rem;
  }
  _::-webkit-full-page-media, _:future, :root .menu {
    min-width: 40rem;
  }
}

@media(max-width: 350px) {
  _::-webkit-full-page-media, _:future, :root .menu {
    min-width: 30rem;
  }
}

.menu__outras {
  position: relative;
  top: 2.5%;
  box-shadow: 0px .3rem .5rem black;
  border-radius: 1rem;
  background-color: white;
  height: 80%;
  text-align: center;
}

.menu__outras p {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 4rem;
  font-weight: lighter;
  white-space: nowrap;
}

@media(max-width: 900px) {
  .menu__outras p {
    font-size: 4.5rem;
  }
}

@media(max-width: 800px) {
  .menu__outras p {
    font-size: 5rem;
  }
}

@media(max-width: 600px) {
  .menu__outras p {
    font-size: 6rem;
  }
}
