.ferramenta {
  position: relative;
  width: 95vw;
  height: 50rem;
  margin: 0 auto;
  text-align: center;
  z-index: 2;
  max-width: 85rem;
  max-height: 45rem;
  margin-bottom: 1.5%;
}

@media(max-width: 700px) {
  .ferramenta {
    height: auto;
    max-height: none;
  }
}
