* {
  font-family: 'Open Sans', sans-serif;
}

html {
  font-size: 10px
}

html, body {
    max-width: 100vw;
    overflow-x: hidden;
}

body {
  position: relative
}

@media(max-width: 900px) {
  html {
    font-size: 9px;
  }
}

@media(max-width: 800px) {
  html {
    font-size: 8px;
  }
}

@media(max-width: 600px) {
  html {
    font-size: 7px;
  }
}

@media(max-width: 500px) {
  html {
    font-size: 6px;
  }
}

@media(min-width: 1480px) {
  html {
    font-size: 12px;
  }
}

@media(min-width: 1680px) {
  html {
    font-size: 14px;
  }
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  opacity: 1;
  width: 0rem;
}

body::-webkit-scrollbar-corner{
  opacity: 1;
}

.app {
  padding-top: 2rem;
}

.aviso-apple {
  display: none;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 40;
  background-color: yellow;
  text-align: center;
  margin: 0;
}

.aviso-apple__texto {
  font-size: 2.15rem
}

.pagina-principal {
  padding-bottom: 2rem;
}

_::-webkit-full-page-media, _:future, :root .aviso-apple {
  display: block;
}

.tech-facs {
  width: 95vw;
  margin: 0 auto;
  margin-top: 2.5vh;
  margin-bottom: 2.5vh;
  background-image: linear-gradient(315deg, rgba(2,0,36,0.8) 0%, rgba(9,9,121,0.8) 35%, rgba(0,212,255,0.8) 100%);
}
