.opcoes-de-au {
  position: relative;
  display: inline-block;
  width: 23.33333%;
  height: 4rem;
  border-radius: 1rem;
  text-align: center;
  color: white;
  margin-top: 2.5%;
  font-size: 1.6rem;
  background-color: green;
}

.opcoes-de-au:hover {
  transform: translateY(-.1rem);
  cursor: pointer;
  box-shadow: 0px .3rem .5rem black;
}

.opcoes-de-au:active {
  transform: translateY(0.05rem);
  cursor: pointer;
  box-shadow: 0px .2rem .5rem black;
}

.opcoes-de-au__texto {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  font-size: 2rem;
}

@media(max-width: 700px) {
  .opcoes-de-au {
    height: 6rem;
  }
  .opcoes-de-au__texto {
    font-size: 2.5rem;
  }
}
