.promover-vendas {
  background-image: linear-gradient(135deg, rgba(2, 0, 36, 0.8) 0%, rgba(0, 212, 255, 0.8) 35%, rgba(0, 212, 255, 0.8) 100%);
  text-align: center;
  border-radius: 5rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.promover-vendas__titule {
  font-size: 4rem;
  margin: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
}
