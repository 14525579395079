.promocao {
  margin: 0 auto;
  width: 95vw;
  height: 100%;
  background-image: linear-gradient(135deg, rgba(2, 0, 36, 0.8) 0%, rgba(9, 9, 121, 0.8) 35%, rgba(0, 212, 255, 0.8) 100%);
  clip-path: polygon(0 8.5rem, 0 100%, 100% 100%, 100% 0);
}

.promocao__titule {
  position: relative;
  left: 50%;
  padding-top: 10rem;
  transform: translateX(-50%);
  color: white;
  font-size: 2.75rem;
  width: 100%;
  text-align: center;
  margin-bottom: 5rem;
  margin-top: 0;
}

.promocao__caixa {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  position: relative;
  margin: 0 auto;
  padding-bottom: 3rem;
  width: auto;
  height: auto;
  row-gap: 3rem;
}

@media (max-width: 560px) {
  .promocao__caixa {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 300px) {
  .promocao__caixa {
    grid-template-columns: 1fr;
  }
}

.promocao__caixa-1 {
  position: relative;
  margin: 0 auto;
  height: 30rem;
  width: 17.5rem;
  background-color: #b77600;
  box-shadow: 0px 1px 3px 1px;
  transition: all .2s;
  border-radius: 10px;
}

@media (max-width: 560px) {
  .promocao__caixa-1 {
    width: 22.5rem;
  }
}

.promocao__caixa-1:hover {
  transform: translateY(-3%);
  box-shadow: 0px 4px 5px 1.5px;
}

.promocao__caixa-1__icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-image: url('./../images/coracao_lupa.png');
  background-position: center;
  background-size: 58%;
  background-repeat: no-repeat;
  width: 100%;
  height: 35%;
  top: 10%;
}

.promocao__caixa-1__titule {
  font-size: 1.7rem;
  color: white;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  top: 50%;
}

@media (max-width: 560px) {
  .promocao__caixa-1__titule {
    top: 55%;
  }
}

.promocao__caixa-1__sub-titule {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  color: white;
  width: 95%;
  text-align: center;
  top: 68%;
}

.promocao__caixa-2 {
  position: relative;
  margin: 0 auto;
  height: 30rem;
  width: 17.5rem;
  background-color: #b77600;
  box-shadow: 0px 1px 3px 1px;
  transition: all .2s;
  border-radius: 10px;
}

@media (max-width: 560px) {
  .promocao__caixa-2 {
    width: 22.5rem;
  }
}

.promocao__caixa-2:hover {
  transform: translateY(-3%);
  box-shadow: 0px 4px 5px 1.5px;
}

.promocao__caixa-2__icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 35%;
  top: 10%;
  background-image: url('./../images/chess.png');
  background-position: center;
  background-size: 58%;
  background-repeat: no-repeat;
}

.promocao__caixa-2__titule {
  font-size: 1.7rem;
  color: white;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  top: 50%;
}

@media (max-width: 500px) {
  .promocao__caixa-2__titule {
    top: 55%;
  }
}

.promocao__caixa-2__sub-titule {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  color: white;
  width: 95%;
  text-align: center;
  top: 68%;
}

.promocao__caixa-3 {
  position: relative;
  margin: 0 auto;
  height: 30rem;
  width: 17.5rem;
  background-color: #b77600;
  box-shadow: 0px 1px 3px 1px;
  transition: all .2s;
  border-radius: 10px;
}

@media (max-width: 560px) {
  .promocao__caixa-3 {
    width: 22.5rem;
  }
}

.promocao__caixa-3:hover {
  transform: translateY(-3%);
  box-shadow: 0px 4px 5px 1.5px;
}

.promocao__caixa-3__icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 35%;
  top: 10%;
  background-image: url('./../images/pinoquio.png');
  background-position: center;
  background-size: 58%;
  background-repeat: no-repeat;
}

.promocao__caixa-3__titule {
  font-size: 1.7rem;
  color: white;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  top: 50%;
}

@media (max-width: 500px) {
  .promocao__caixa-3__titule {
    top: 55%;
  }
}

.promocao__caixa-3__sub-titule {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  color: white;
  width: 95%;
  text-align: center;
  top: 68%;
}

.promocao__caixa-4 {
  position: relative;
  margin: 0 auto;
  height: 30rem;
  width: 17.5rem;
  background-color: #b77600;
  box-shadow: 0px 1px 3px 1px;
  transition: all .2s;
  border-radius: 10px;
}

@media (max-width: 560px) {
  .promocao__caixa-4 {
    width: 22.5rem;
  }
}

.promocao__caixa-4:hover {
  transform: translateY(-3%);
  box-shadow: 0px 4px 5px 1.5px;
}

.promocao__caixa-4__icon {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 35%;
  top: 8%;
  background-image: url('./../images/handshake.png');
  background-position: center;
  background-size: 70%;
  background-repeat: no-repeat;
}

.promocao__caixa-4__titule {
  font-size: 1.7rem;
  color: white;
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: bold;
  top: 42.5%;
}

@media (max-width: 500px) {
  .promocao__caixa-4__titule {
    top: 50%;
  }
}

.promocao__caixa-4__sub-titule {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  color: white;
  width: 95%;
  text-align: center;
  top: 68%;
}

@media (max-width: 560px) {
  .promocao__caixa-1__icon {
    background-size: 50%;
  }
  .promocao__caixa-2__icon {
    background-size: 45%;
  }
  .promocao__caixa-3__icon {
    background-size: 45%;
  }
  .promocao__caixa-4__icon {
    background-size: 55%;
  }
}

@media(max-width: 800px) {
  .promocao {
    clip-path: polygon(0 10rem, 0 100%, 100% 100%, 100% 0);
  }
};


@media (max-width: 800px) {
  .promocao__caixa-1__sub-titule {
    font-size: 1.6rem;
  }
  .promocao__caixa-2__sub-titule {
    font-size: 1.6rem;
  }
  .promocao__caixa-3__sub-titule {
    font-size: 1.6rem;
  }
  .promocao__caixa-4__sub-titule {
    font-size: 1.6rem;
  }
}

@media (max-width: 600px) {
  .promocao__caixa-1__sub-titule {
    font-size: 1.4rem;
  }
  .promocao__caixa-2__sub-titule {
    font-size: 1.4rem;
  }
  .promocao__caixa-3__sub-titule {
    font-size: 1.4rem;
  }
  .promocao__caixa-4__sub-titule {
    font-size: 1.4rem;
  }
}

@media (max-width: 550px) {
  .promocao__caixa-1__sub-titule {
    font-size: 1.6rem;
  }
  .promocao__caixa-2__sub-titule {
    font-size: 1.6rem;
  }
  .promocao__caixa-3__sub-titule {
    font-size: 1.6rem;
  }
  .promocao__caixa-4__sub-titule {
    font-size: 1.6rem;
  }
}
