.marcador-de-au {
  position: absolute;
  z-index: 2;
}

.marcador-de-au__caixa {
  height: 4rem;
  width: 15rem;
  background-color: gray;
  border: .1rem solid black;
  text-align: center;
  line-height: 1rem;
  box-shadow: 0px .3rem .5rem black;
  cursor: pointer;
  font-size: 1.6rem;
}

@media(max-width: 400px) {
  _::-webkit-full-page-media, _:future, :root .marcador-de-au__texto {
    font-size: 2rem;
  }
}

.marcador-de-au__barra {
  height: .2rem;
  width: 7.5rem;
  overflow: hidden;
}

.marcador-de-au__linha {
  height: .2rem;
  width: 7.5rem;
  background-color: white;
  border-radius: 3rem;
}

.marcador-de-au__point {
  height: .8rem;
  width: .8rem;
  background-color: white;
  border-radius: 50%;
}

.barra__baixo-direita-1 {
  transform: translateX(14rem) translateY(2.5rem) rotate(45deg);
}

.point__baixo-direita-1 {
  transform: translate(20rem, 4.6rem);
}

.barra__baixo-esquerda-1 {
  transform: translateX(-6.3rem) translateY(2.4rem) rotate(135deg);
}

.point__baixo-esquerda-1 {
  transform: translate(-5.5rem, 4.4rem);
}

.barra__alto-esquerda-1 {
  transform: translateX(-6.3rem) translateY(-6.8rem) rotate(225deg);
}

.point__alto-esquerda-1 {
  transform: translate(-5.5rem, -10rem);
}

.barra__alto-direita-1 {
  transform: translateX(14rem) translateY(-6.8rem) rotate(315deg);
}

.point__alto-direita-1 {
  transform: translate(20.1rem, -10rem);
}
