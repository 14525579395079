.caixa-de-cadastro__background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.9);
  z-index: 201;
  font-size: 100rem;
}

.caixa-de-cadastro {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 57rem;
  width: 50%;
  z-index: 200;
  background-color: white;
  border-radius: 1rem;
  z-index: 202;
  min-width: 35rem;
  max-width: 50rem;
}

.caixa-de-cadastro__exit {
  position: absolute;
  top: 1%;
  right: 1%;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
}

.caixa-de-cadastro__exit-1 {
  position: absolute;
  top: 45%;
  left: 15%;
  height: .4rem;
  width: 3.5rem;
  background-color: black;
  border-radius: 10px;
  transform: rotate(45deg);
}

.caixa-de-cadastro__exit-2 {
  position: absolute;
  top: 45%;
  left: 15%;
  height: .4rem;
  width: 3.5rem;
  background-color: black;
  border-radius: 10px;
  transform: rotate(135deg);
}

.caixa-de-cadastro__caixa-central {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30rem;
}

.caixa-de-cadastro__titulo {
  font-size: 3rem;
  white-space: nowrap;
  margin: 0 auto;
  text-align: center;
}

.caixa-de-cadastro__cadastrarGoogle {
  position: relative;
  height: 5rem;
  width: 100%;
  margin-top: 2rem;
  background-color: #DE4A39;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.caixa-de-cadastro__cadastrarGoogle img {
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
}

.caixa-de-cadastro__cadastrarFacebook {
  position: relative;
  height: 5rem;
  width: 100%;
  margin-top: 0rem;
  background-color: #4769B0;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.caixa-de-cadastro__cadastrarFacebook img {
  height: 3rem;
  position: absolute;
  top: 50%;
  left: 6%;
  transform: translateY(-50%);
}

.caixa-de-cadastro__cadastrarTwitter {
  position: relative;
  height: 5rem;
  width: 100%;
  margin-top: 0rem;
  background-color: #00A2F9;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 3px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.caixa-de-cadastro__cadastrarTwitter img {
  height: 2.5rem;
  position: absolute;
  top: 50%;
  left: 4%;
  transform: translateY(-50%);
}

.caixa-de-cadastro__texto {
  text-align: center;
  font-size: 1.6rem;
  margin-top: 2rem;
}

.texto-email {
  font-size: 2rem;
}

#input-email {
  height: 3.5rem;
  width: 100%;
  padding: 0;
  border: 0;
  margin-bottom: 1.25rem;
  background-color: lightgray;
  font-size: 1.6rem;
}

#input-email:focus, #input-email:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.texto-senha {
  font-size: 2rem;
}

#input-senha {
  height: 3.5rem;
  width: 100%;
  padding: 0;
  border: 0;
  margin-bottom: 2rem;
  background-color: lightgray;
  font-size: 1.6rem;
}

#input-senha:focus, #input-senha:focus {
    box-shadow: 0 0 0 0;
    outline: 0;
}

.caixa-de-cadastro__cadastrarEmail {
  height: 5rem;
  width: 100%;
  margin-top: 0rem;
  background-color: green;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 3px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.caixa-de-cadastro__possue-login {
  display: block;
  text-align: center;
  font-size: 1.6rem;
  color: #0000FF;
  cursor: pointer;
  text-decoration: underline;
}

.caixa-de-cadastro__possue-login:hover {
  color: #551A8B;

}

@media(max-width: 900px) {
  .caixa-de-cadastro {
    min-width: 40rem;
    height: 70rem;
  }
  .caixa-de-cadastro__caixa-central {
    width: 38rem;
  }
  .caixa-de-cadastro__titulo {
    font-size: 4rem;
  }
  .caixa-de-cadastro__cadastrarGoogle {
    height: 6rem;
    line-height: 6rem;
    font-size: 2rem;
  }
  .caixa-de-cadastro__cadastrarGoogle img {
    height: 3.5rem;
  }
  .caixa-de-cadastro__cadastrarFacebook {
    height: 6rem;
    line-height: 6rem;
    font-size: 2rem;
  }
  .caixa-de-cadastro__cadastrarFacebook img {
    height: 4.5rem;
  }
  .caixa-de-cadastro__cadastrarTwitter {
    height: 6rem;
    line-height: 6rem;
    font-size: 2rem;
  }
  .caixa-de-cadastro__cadastrarTwitter img {
    height: 3.5rem;
  }
  .caixa-de-cadastro__texto {
    font-size: 2rem;
  }
  .texto-email {
    font-size: 2.5rem;
  }
  .texto-senha {
    font-size: 2.5rem;
  }
  #input-email {
    height: 4.5rem;
    font-size: 2rem;
  }
  #input-senha {
    height: 4.5rem;
    font-size: 2rem;
  }
  .caixa-de-cadastro__cadastrarEmail {
    height: 6rem;
    line-height: 6rem;
    font-size: 2rem;
  }
  .caixa-de-cadastro__possue-login {
    font-size: 2rem;
  }
}

@media(max-width: 800px) {
  .caixa-de-cadastro {
    height: 70rem;
    min-width: 45rem;
  }
  .caixa-de-cadastro__caixa-central {
    width: 42rem;
  }
  .caixa-de-cadastro__cadastrarGoogle img {
    height: 4rem;
  }
  .caixa-de-cadastro__cadastrarFacebook img {
    height: 4rem;
  }
  .caixa-de-cadastro__cadastrarTwitter img {
    height: 3.5rem;
  }
}

@media(max-width: 600px) {
  .caixa-de-cadastro {
    height: 80rem;
    min-width: 50rem;
  }
  .caixa-de-cadastro__caixa-central {
    width: 47rem;
  }
  .caixa-de-cadastro__titulo {
    font-size: 4.7rem;
  }
  .caixa-de-cadastro__cadastrarGoogle {
    height: 7rem;
    line-height: 7rem;
    font-size: 2.5rem;
  }
  .caixa-de-cadastro__cadastrarFacebook {
    height: 7rem;
    margin-top: 0.5rem;
    line-height: 7rem;
    font-size: 2.5rem;
  }
  .caixa-de-cadastro__cadastrarTwitter {
    height: 7rem;
    margin-top: 0.5rem;
    line-height: 7rem;
    font-size: 2.5rem;
  }
  .caixa-de-cadastro__cadastrarGoogle img {
    height: 4rem;
  }
  .caixa-de-cadastro__cadastrarFacebook img {
    height: 5rem;
  }
  .caixa-de-cadastro__cadastrarTwitter img {
    height: 4rem;
  }
  .caixa-de-cadastro__texto {
    font-size: 2.5rem;
  }
  .texto-email {
    font-size: 3rem;
  }
  .texto-senha {
    font-size: 3rem;
  }
  #input-email {
    height: 5rem;
    font-size: 3rem;
  }
  #input-senha {
    height: 5rem;
    font-size: 3rem;
  }
  .caixa-de-cadastro__cadastrarEmail {
    height: 7rem;
    line-height: 7rem;
    font-size: 2.5rem;
  }
  .caixa-de-cadastro__possue-login {
    font-size: 2.5rem;
  }
}

@media(max-width: 500px) {
  .caixa-de-cadastro {
    height: 80rem;
  }
}
