.header-vendas {
  background-image: linear-gradient(315deg, rgba(2, 0, 36, 0.8) 0%, rgba(9, 9, 121, 0.8) 35%, rgba(0, 212, 255, 0.8) 100%);
  height: 65rem;
  border-bottom-right-radius: 5rem;
  border-bottom-left-radius: 5rem;
  color: white;
  overflow-x: hidden;
}

.header-vendas__logo {
  width: 17.5rem;
}

.header-vendas__texto {
  position: absolute;
  top: 22rem;
  width: 55rem;
  margin-left: 2rem;
}

.header-vendas__titule {
  font-size: 5rem;
  font-weight: 400;
  letter-spacing: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.header-vendas__sub-titule {
  margin-top: 2rem;
  font-size: 3rem;
  font-weight: 300;
}

.header-vendas__button {
  display: inline-block;
  font-size: 2.25rem;
  background-color: white;
  text-align: center;
  padding: 1rem 2rem;
  cursor: pointer;
  color: black;
}

@media(max-width: 440px) {
  .apple-header-vendas__texto {
    width: auto; 
  }
  .header-vendas__titule {
    font-size: 7rem;
  }
  .header-vendas__sub-titule {
    margin-top: 2rem;
    font-size: 3.5rem;
  }
  .header-vendas__button {
    font-size: 3rem;
  }
}
