.navbar__background {
  position: fixed;
  top: 4.2rem;
  right: 4.2rem;
  height: 6rem;
  width: 6rem;
  border-radius: 50%;
  background: radial-gradient(circle, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%);
  transition: all 1s;
  z-index: 99;
}

.navbar__background__list {
  position: fixed;
  top: 50%;
  left: 140%;
  opacity: 1;
  transform: translate(-50%, -50%);
  opacity: 0;
  list-style-type: none;
  z-index: 101;
  transition: all 1s;
}

.navbar__background__list * {
  text-decoration: none;
  color: white;
  font-size: 3rem;
  line-height: 7rem;
  text-transform: uppercase;
  font-weight: 100;
  white-space: nowrap;
  cursor: pointer;
}

.navbar__checkbox {
  display: none;
  z-index: 100;
}

.navbar__checkbox:checked ~ .navbar__background {
  transform: scale(90);
  position: fixed;
}

.navbar__checkbox:checked ~ .navbar__background__list {
  opacity: 1;
  left: 50%;
}

.navbar__button {
  position: fixed;
  top: 4rem;
  right: 4rem;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;
  box-shadow: 0 2px 3px 2px black;
  transition: all .1s;
  z-index: 101;
}

.navbar__button:hover {
  top: 3.6rem;
  box-shadow: 0 .5rem .3rem .2rem black;
}

.navbar__button:active {
  top: 3.8rem;
  box-shadow: 0 .3rem .3rem .2rem black;
}

.navbar__hamburguer-center {
  height: .5rem;
  width: 4rem;
  background-color: black;
  border-radius: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar__hamburguer-top {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 30%;
  height: .5rem;
  width: 4rem;
  background-color: black;
  border-radius: 10rem;
}

.navbar__hamburguer-bottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30%;
  height: .5rem;
  width: 4rem;
  background-color: black;
  border-radius: 10rem;
}

@media(max-width: 600px) {
  .navbar__background {
    height: 7rem;
    width: 7rem;
  }
  .navbar__button {
    height: 7.5rem;
    width: 7.5rem;
  }
  .navbar__hamburguer-center {
    height: .7rem;
    width: 5rem;
  }
  .navbar__hamburguer-top {
    height: .7rem;
    width: 5rem;
    top: 28%;
  }
  .navbar__hamburguer-bottom {
    height: .7rem;
    width: 5rem;
    bottom: 28%;
    backface-visibility: hidden;
  }
}
