.bottom-bar {
  top: 2.5%;
}

.bottom-bar__emotions {
  position: relative;
  height: 100%;
  margin: 0 auto;
}

.alegria {
  position: absolute;
  top: 2.5%;
  left: 50%;
  transform: translateX(-50%);
}

.tristeza {
  position: absolute;
  top: 15%;
  left: 50%;
  transform: translateX(-50%);
}

.raiva {
  position: absolute;
  top: 26.5%;
  left: 50%;
  transform: translateX(-50%);
}

.nojo {
  position: absolute;
  top: 39%;
  left: 50%;
  transform: translateX(-50%);
}

.desprezo {
  position: absolute;
  top: 51.5%;
  left: 50%;
  transform: translateX(-50%);
}

.medo {
  position: absolute;
  top: 64%;
  left: 50%;
  transform: translateX(-50%);
}

.surpresa {
  position: absolute;
  top: 76.5%;
  left: 50%;
  transform: translateX(-50%);
}

.neutro {
  position: absolute;
  top: 89%;
  left: 50%;
  transform: translateX(-50%);
}
