.center-image {
  display: inline-block;
  width: 55%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
}

@media(max-width: 700px) {
  .center-image {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
    height: 45rem;
  }
}

.center-image__box {
  position: relative;
  padding-top: .1px;
  left: 50%;
  transform: translateX(-50%);
  width: 55%;
  height: 100%;
  min-width: 25rem;
}

.center-image__image {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  width: 100%;
  height: 80%;
  background-color: black;
  border-radius: 2rem;
}

.center-image__center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-image: url("./image/neutro.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
  height: 90%;
  width: 90%;
}

.alegria-img {
  background-image: url("./image/alegria.png");
}

.tristeza-img {
  background-image: url("./image/tristeza.png");
  filter: grayscale(1);
}

.raiva-img {
  background-image: url("./image/raiva.png");
  filter: grayscale(1);
}

.nojo-img {
  background-image: url("./image/nojo.png");
}

.desprezo-img {
  background-image: url("./image/desprezo.png");
}

.medo-img {
  background-image: url("./image/medo.png");
  filter: grayscale(1);
}

.surpresa-img {
  background-image: url("./image/surpresa.png");
  filter: grayscale(1);
}

.au-1-img{
  background-image: url("./fotos-aus/s1.png");
}

.au-2-img{
  background-image: url("./fotos-aus/s2.png");
}

.au-4-img{
  background-image: url("./fotos-aus/s4b.png");
}

.au-5-img{
  background-image: url("./fotos-aus/s5z.png");
}

.au-6-img{
  background-image: url("./fotos-aus/s6.png");
}

.au-7-img{
  background-image: url("./fotos-aus/s7.png");
}

.au-9-img{
  background-image: url("./fotos-aus/s9.png");
}

.au-10-img{
  background-image: url("./fotos-aus/s10y.png");
}

.au-11-img{
  background-image: url("./fotos-aus/s11.png");
}

.au-12-img{
  background-image: url("./fotos-aus/s12y_25.png");
}

.au-13-img{
  background-image: url("./fotos-aus/s13_25.png");
}

.au-14-img{
  background-image: url("./fotos-aus/s14.png");
}

.au-15-img{
  background-image: url("./fotos-aus/s15z.png");
}

.au-16-img{
  background-image: url("./fotos-aus/s16_25.png");
}

.au-17-img{
  background-image: url("./fotos-aus/s17.png");
}

.au-18-img{
  background-image: url("./fotos-aus/s18b.png");
}

.au-20-img{
  background-image: url("./fotos-aus/s20x.png");
}

.au-21-img{
  background-image: url("./fotos-aus/s21.png");
}

.au-22-img{
  background-image: url("./fotos-aus/s22_25.png");
}

.au-23-img{
  background-image: url("./fotos-aus/s23.png");
}

.au-24-img{
  background-image: url("./fotos-aus/s24.png");
}

.au-25-img{
  background-image: url("./fotos-aus/s25.png");
}

.au-26-img{
  background-image: url("./fotos-aus/s26.png");
}

.au-27-img{
  background-image: url("./fotos-aus/s27.png");
}

.au-28-img{
  background-image: url("./fotos-aus/s28.png");
}

.au-29-img{
  background-image: url("./fotos-aus/s29.png");
}

.au-41-img{
  background-image: url("./fotos-aus/s41.png");
}

.au-43-img{
  background-image: url("./fotos-aus/s43.png");
}

.au-45-img{
  background-image: url("./fotos-aus/s43.png");
}

.au-46-img{
  background-image: url("./fotos-aus/s43.png");
}

.alegria__au-6 {
  top: 3rem;
  right: 22rem;
}

.alegria__au-12 {
  top: 26rem;
  right: 22rem;
}

.tristeza__au-1 {
  left: 19rem;
  top: 1.5rem;
}

.tristeza__au-4 {
  right: 17rem;
  top: 2rem;
}

.tristeza__au-15 {
  left: 21rem;
  top: 29rem;
}

.tristeza__au-17 {
  right: 16.5rem;
  top: 31rem;
}

.tristeza__au-41 {
  right: 20rem;
  top: 19rem;
}

.raiva__au-4 {
  right: 18rem;
  top: 4rem;
}

.raiva__au-5 {
  left: 20rem;
  top: 4.5rem;
}

.raiva__au-7 {
  right: 20rem;
  top: 21rem;
}

.raiva__au-23 {
  left: 17rem;
  top: 27.5rem;
}

.raiva__au-29 {
  right: 17rem;
  top: 31rem;
}

.nojo__au-9 {
  top: 6.5rem;
  right: 19rem;
}

.nojo__au-17 {
  top: 33rem;
  right: 17.5rem;
}

.desprezo__au-14 {
  top: 28rem;
  left: 21rem;
}

.medo__au-1 {
  top: 0rem;
  left: 18.5rem;
}

.medo__au-2 {
  top: 0rem;
  right: 21rem;
}

.medo__au-4 {
  top: 16rem;
  right: 17.5rem;
}

.medo__au-5 {
  top: 17rem;
  left: 20.5rem;
}

.medo__au-20 {
  top: 28rem;
  left: 19.5rem;
}

.surpresa__au-1 {
  top: 2rem;
  left: 19rem;
}

.surpresa__au-2 {
  top: 2rem;
  right: 21.5rem;
}

.surpresa__au-5 {
  top: 19rem;
  left: 20.5rem;
}

.surpresa__au-25 {
  top: 28em;
  right: 17rem;
}

.surpresa__au-26 {
  top: 28rem;
  left: 18rem;
}

.au-1 {
  left: 18rem;
}

.au-2 {
  top: -1.5rem;
  right: 22rem;
}

.au-4 {
  top: 1.5rem;
  right: 19rem;
}

.au-5 {
  top: 2.5rem;
  left: 21rem;
}

.au-6 {
  top: 19rem;
  right: 22rem;
}

.au-7 {
  top: 19rem;
  left: 21rem;
}

.au-9 {
  top: 6rem;
  right: 19rem;
}

.au-10 {
  top: 11rem;
  right: 18.5rem;
}

.au-11 {
  top: 27rem;
  left: 22rem;
}

.au-12 {
  top: 27rem;
  right: 22rem;
}

.au-13 {
  top: 26rem;
  right: 22.5rem;
}

.au-14 {
  top: 27.5rem;
  right: 22.5rem;
}

.au-15 {
  top: 29rem;
  left: 20.75rem;
}

.au-16 {
  top: 30.5rem;
  left: 16rem;
}

.au-17 {
  top: 32.5rem;
  left: 16rem;
}

.au-18 {
  top: 13rem;
  right: 17rem;
}

.au-20 {
  top: 30rem;
  right: 22rem;
}

.au-21 {
  top: 33rem;
  right: 22rem;
}

.au-22 {
  top: 13rem;
  left: 17rem;
}

.au-23 {
  top: 13.5rem;
  right: 17rem;
}

.au-24 {
  top: 13.5rem;
  left: 17rem;
}

.au-25 {
  top: 28.5rem;
  left: 17rem;
}

.au-26 {
  top: 28.5rem;
  left: 17rem;
}

.au-27 {
  top: 13rem;
  left: 17rem;
}

.au-28 {
  top: 14rem;
  left: 17rem;
}

.au-29 {
  top: 35rem;
  right: 17rem;
}

.au-41 {
  top: 3.75rem;
  right: 22.5rem;
}

.au-43 {
  top: 4.75rem;
  left: 20.5rem;
}

.au-45 {
  top: 5.1rem;
  left: 21.5rem;
}

.au-46 {
  top: 3.75rem;
  right: 21.5rem;
}
