.pesquisa-de-satisfacao__background {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100vh;
  width: 100vw;
  background-color: rgba(0,0,0,0.9);
  z-index: 201;
  font-size: 100rem;
}

.pesquisa-de-satisfacao {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  z-index: 200;
  background-color: white;
  border-radius: 1rem;
  z-index: 202;
  min-width: 35rem;
  max-width: 50rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pesquisa-de-satisfacao__exit {
  position: absolute;
  top: 1%;
  right: 1%;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
}

.pesquisa-de-satisfacao__exit-1 {
  position: absolute;
  top: 45%;
  left: 15%;
  height: .4rem;
  width: 3.5rem;
  background-color: black;
  border-radius: 10px;
  transform: rotate(45deg);
}

.pesquisa-de-satisfacao__exit-2 {
  position: absolute;
  top: 45%;
  left: 15%;
  height: .4rem;
  width: 3.5rem;
  background-color: black;
  border-radius: 10px;
  transform: rotate(135deg);
}

.pesquisa-de-satisfacao__centro {
  text-align: center;
}

.pesquisa-de-satisfacao__titulo {
  font-size: 3rem;
  margin-top: 2rem;
}

.pesquisa-de-satisfacao__feedback {
  margin: 0;
  padding: 0;
  width: 65%;
  height: 10rem;
  font-size: 1.6rem;
}

.pesquisa-de-satisfacao__submit {
  height: 5rem;
  width: 65%;
  margin: 0 auto;
  margin-top: 2rem;
  background-color: green;
  line-height: 5rem;
  text-align: center;
  font-size: 1.6rem;
  border-radius: 3px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}

.pesquisa-de-satisfacao__botao {
  position: fixed;
  bottom: 4rem;
  right: 4rem;
  height: 6.5rem;
  width: 6.5rem;
  border-radius: 50%;
  background-color: #FFA500;
  cursor: pointer;
  box-shadow: 0 2px 3px 2px black;
  z-index: 50;
}

.pesquisa-de-satisfacao__icone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 5rem;
  font-weight: bolder;
  margin: 0;
  color: white;
}

@media(max-width: 900px) {
  .pesquisa-de-satisfacao {
    width: 50%;
    min-width: 40rem;
    max-width: 100rem;
  }
  .pesquisa-de-satisfacao__submit {
    height: 6rem;
    line-height: 6rem;
    font-size: 2rem;
  }
}

@media(max-width: 800px) {
  .pesquisa-de-satisfacao {
    width: 60%;
  }
  .pesquisa-de-satisfacao__feedback {
    height: 15rem;
    font-size: 2rem;
  }
  .pesquisa-de-satisfacao__titulo {
    font-size: 3.5rem;
  }
}

@media(max-width: 600px) {
  .pesquisa-de-satisfacao {
    width: 80%;
  }
  .pesquisa-de-satisfacao__feedback {
    height: 17rem;
    font-size: 2.25rem;
  }
  .pesquisa-de-satisfacao__submit {
    height: 7rem;
    line-height: 7rem;
    font-size: 2.5rem;
  }
  .pesquisa-de-satisfacao__botao {
    height: 7.5rem;
    width: 7.5rem;
  }
}

@media(max-width: 500px) {
  .pesquisa-de-satisfacao__titulo {
    font-size: 3.75rem;
  }
}
