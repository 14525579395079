.draggable-main {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: 'Raleway', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f0f0f0;
}

.content {
  position: relative;
  width: 83.25rem;
  height: 240px;
  margin: 0 auto;
  margin-top: 5rem;
}

.content > div {
  position: absolute;
  width: 80rem;
  height: 9rem;
  overflow: visible;
  pointer-events: auto;
  transform-origin: 50% 50% 0px;
  border-radius: 5px;
  color: white;
  line-height: 90px;
  padding-left: 32px;
  font-size: 14.5px;
  background: lightblue;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.content > div:nth-child(1) {
  background: linear-gradient(135deg, #f6d365 0%, #fda085 100%);
}
.content > div:nth-child(2) {
  background: linear-gradient(135deg, rgba(137,223,144,1) 0%, rgba(0,99,5,1) 100%);
}
.content > div:nth-child(3) {
  background: linear-gradient(135deg, #5ee7df 0%, #b490ca 100%);
}
.content > div:nth-child(4) {
  background: linear-gradient(135deg, #c3cfe2 0%, #c3cfe2 100%);
}

@media(max-width: 720px) {
  .content {
    width: 73.25rem;
  }
  .content > div {
    width: 70rem;
  }
}

@media(max-width: 580px) {
  .content {
    width: 63.25rem;
  }
  .content > div {
    width: 60rem;
  }
}

@media(max-width: 430px) {
  .content {
    width: 53.25rem;
  }
  .content > div {
    width: 50rem;
  }
}

@media(max-width: 360px) {
  .content {
    width: 43.25rem;
  }
  .content > div {
    width: 40rem;
  }
}
