.opcoes-de-instrucoes {
  display: inline-block;
  background-color: gray;
  color: white;
  margin-right: 2rem;
  border-radius: 1rem;
  width: 10rem;
  height: 4rem;
  text-align: center;
  cursor: pointer;
}

.opcoes-de-instrucoes:hover {
  transform: translateY(-1px);
  box-shadow: 0px .3rem .5rem black;
}

.opcoes-de-instrucoes:active {
  transform: translateY(.5px);
  box-shadow: 0px .2rem .5rem black;
}

.opcoes-de-instrucoes__texto {
  line-height: 4rem;
  font-size: 1.6rem;
  margin: 0;
}

@media(max-width: 700px) {
  .opcoes-de-instrucoes__texto {
    font-size: 1.8rem;
    line-height: 4.5rem;
  }
  .opcoes-de-instrucoes {
    width: 11.5rem;
    height: 4.5rem;
  }
}

@media(max-width: 600px) {
  .opcoes-de-instrucoes__texto {
    font-size: 1.9rem;
  }
  _::-webkit-full-page-media, _:future, :root .opcoes-de-instrucoes {
    margin-top: 1rem;
  }
}

@media(max-width: 500px) {
  .opcoes-de-instrucoes__texto {
    font-size: 2rem;
  }
}

@media(max-width: 385px) {
  .opcoes-de-instrucoes {
    margin-top: 1rem;
    height: 5rem;
    width: 18rem;
  }
  .opcoes-de-instrucoes__texto {
    line-height: 5rem;
  }
}
