.realilzar-login__titulo {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 0;
}

.realilzar-login__sub-titulo {
  margin-top: 1.5rem;
  font-size: 2rem;
  text-align: center;
}

.realilzar-login__button {
  margin: 0 auto;
  margin-top: 1.5rem;
  width: 60%;
  height: 5rem;
  border-radius: 1rem;
  text-align: center;
  background-color: #FF9600;
  font-size: 2rem;
  color: white;
  line-height: 5rem;
  cursor: pointer;
}

@media(max-width: 800px) {
  .realilzar-login__titulo {
    font-size: 3.25rem;
  }
  .realilzar-login__sub-titulo {
    font-size: 2.25rem;
  }
  .realilzar-login__button {
    height: 5.5rem;
    font-size: 2.25rem;
    line-height: 5.5rem;
  }
}

@media(max-width: 500px) {
  .realilzar-login__titulo {
    font-size: 3.5rem;
  }
  .realilzar-login__sub-titulo {
    font-size: 2.5rem;
  }
  .realilzar-login__button {
    height: 6rem;
    font-size: 2.5rem;
    line-height: 6rem;
    width: 80%;
  }
}
