.comparacao {
  text-align: center;
  margin-bottom: 5rem;
}

.comparacao__titule {
  font-size: 4rem;
  margin: 0;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.comparacao__tabela {
  margin: 0 auto;
  font-size: 2.2rem;
  width: 95%;
}

#coluna-1 {
  text-align: left;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

#coluna-2 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

#coluna-5 {
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-left: 2px solid darkorange;
  border-right: 2px solid darkorange;
}

.linha-1 {
  background-color: rgba(9, 9, 121, 0.8);
  color: white;
}

.linha-2 {
  font-weight: bold;
}

.linha-2, .linha-4 {
  background-color: lightblue;
}

th, td, tr, table {
  position: relative;
  border-collapse: collapse;
}

table {
  border-radius: 2rem;
}

.certo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 3rem;
}

.celula-especial {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  border: none;
  background-color: darkorange;
}

.celula-do-lado {
  border-top-right-radius: 2rem
}
.l1-c1 {
  border-top-left-radius: 2rem
}

.c5-l5 {
  border-bottom: 2px solid darkorange;
}

@media(max-width: 421px)  {
  .comparacao__titule {
    font-size: 6rem;
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

@media(max-width: 400px)  {
  .comparacao__tabela {
    font-size: 2rem;
  }
}

@media(max-width: 360px)  {
  .comparacao__tabela {
    font-size: 1.7rem;
  }
}
