.header {
  position: relative;
  text-align: center;
  color: white;
}

.header__logo {
  position: absolute;
  bottom: 1.62rem;
  left: 0;
}

.header__imagem {
  height: auto;
  width: 17rem;
  cursor: pointer;
}

.header__titule {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 5rem;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-weight: lighter;
}

.header__text {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
  font-size: 2.25rem;
  font-weight: 300;
}

@media(max-width: 400px) {
  .header__imagem {
    height: auto;
    width: 19rem;
  }
}

@media(max-width: 600px) {
  .header__imagem {
    height: auto;
    width: 18rem;
  }
}

@media (max-width: 1140px) {
  .header {
    padding-top: 5.5rem;
  }
  .header__logo {
    bottom: 7.12rem;
  }
}
