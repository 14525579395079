.explicacao {
  text-align: center;
  padding-bottom: 3rem;
}

.explicacao__titule {
  font-size: 4rem;
  margin: 0;
  padding-top: 3rem;
}
