.area-de-compra {
  position: relative;
  top: 2.5%;
  box-shadow: 0px .3rem .5rem black;
  border-radius: 1rem;
  background-color: white;
  height: 80%;
  text-align: center;
}

.area-de-compra__centro {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: auto;
}

.area-de-compra__titulo {
  margin: 0;
  font-size: 2.5rem;
  font-weight: lighter;
}

.area-de-compra__botao {
  display: block;
  margin: 0 auto;
  margin-top: 2rem;
  width: 100%;
  height: 5rem;
  border-radius: 1rem;
  text-align: center;
  background-color: #FF9600;
  font-size: 2rem;
  color: white;
  line-height: 5rem;
  cursor: pointer;
}

.area-de-compra__aus {
  white-space: nowrap;
}

@media(max-width: 900px) {
  .area-de-compra__centro {
    width: 90%;
  }
  .area-de-compra__titulo {
    font-size: 3rem;
  }
  .area-de-compra__botao {
    height: 6rem;
    border-radius: 1rem;
    font-size: 2.5rem;
    line-height: 6rem;
  }
}

@media(max-width: 800px) {
  .area-de-compra__titulo {
    font-size: 3.5rem;
  }
  .area-de-compra__botao {
    height: 6.5rem;
    border-radius: 1rem;
    font-size: 3rem;
    line-height: 6.5rem;
  }
}

@media(max-width: 600px) {
  .area-de-compra__titulo {
    font-size: 4rem;
  }
}
