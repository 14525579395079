.aus-menu {
  position: relative;
  top: 2.5%;
  box-shadow: 0px .3rem .5rem black;
  border-radius: 1rem;
  background-color: white;
  height: 80%;
  text-align: center;
  overflow: scroll;
}

.aus-menu::-webkit-scrollbar {
  opacity: 1;
  width: .5rem;
}

.aus-menu::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2rem;
}

.aus-menu::-webkit-scrollbar-corner{
  opacity: 1;
}

.aus-menu__2 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__6 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__10 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__13 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__16 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__20 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__23 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__26 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__29 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.aus-menu__45 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.acionado {
  background-color: red !important;
}
