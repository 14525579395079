.politica-de-privacidade {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.8rem;
  padding: 2rem;
  padding-top: 0;
  width: 100vw;
  background-color: white;
  margin: 0 auto;
  z-index: 300;
}

.politica-de-privacidade__exit {
  position: fixed;
  top: .2%;
  right: 1%;
  height: 5rem;
  width: 5rem;
  cursor: pointer;
  z-index: 301;
}

.politica-de-privacidade__exit-1 {
  position: absolute;
  top: 45%;
  left: 15%;
  height: .4rem;
  width: 3.5rem;
  background-color: black;
  border-radius: 10px;
  transform: rotate(45deg);
}

.politica-de-privacidade__exit-2 {
  position: absolute;
  top: 45%;
  left: 15%;
  height: .4rem;
  width: 3.5rem;
  background-color: black;
  border-radius: 10px;
  transform: rotate(135deg);
}
