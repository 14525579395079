.center {
  margin: 0 auto;
  margin-top: 2rem;
  width: 95vw;
  height: auto;
  text-align: center;
}

.center__titule {
  height: 100%;
  white-space: normal;
}

.secundary-titule {
  text-align: center;
  margin: auto;
  color: gray;
  font-size: 2.75rem;
}

.center__images {
  height: auto;
  width: 90%;
  text-align: center;
  margin: 0 auto;
  margin-top: 5rem;
}

.center__images-apple {
  display: inline-block;
  position: relative;
  margin: 0 auto;
  height: 15rem;
  width: 15rem;
  transition: all .2s;
  background-image: url('./../images/apple_imagem.png');
  background-position: center;
  background-size: cover;
}

.center__images-apple:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-harvard {
  display: inline-block;
  height: 15rem;
  width: 15rem;
  background-position: center;
  background-size: cover;
  background-image: url('./../images/harvard_imagem.png');
  margin: 0 auto;
  transition: all .2s;
}

.center__images-harvard:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-cia {
  margin: 0 auto;
  display: inline-block;
  height: 15rem;
  width: 15rem;
  background-position: center;
  background-size: cover;
  background-image: url('./../images/cia_imagem.png');
  transition: all .2s;
}

.center__images-cia:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-brown {
  margin: 0 auto;
  display: inline-block;
  height: 15rem;
  width: 15rem;
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  background-image: url('./../images/brown_imagem.png');
  transition: all .2s;
}

.center__images-brown:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-nypd {
  margin: 0 auto;
  height: 15rem;
  width: auto;
  transition: all .2s;
  display: inline-block;
  height: 15rem;
  width: 15rem;
  background-image: url('./../images/nypd_imagem.jpg');
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
}

.center__images-nypd:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-kpmg {
  margin: 0 auto;
  height: auto;
  width: 25rem;
  transition: all .2s;
  display: inline-block;
  height: 15rem;
  width: 15rem;
  background-image: url('./../images/kpmg_imagem.png');
  background-position: center;
  background-size: 95%;
  background-repeat: no-repeat;
}

.center__images-kpmg:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-disney {
  margin: 0 auto;
  height: auto;
  width: 25rem;
  transition: all .2s;
  display: inline-block;
  height: 15rem;
  width: 15rem;
  background-image: url('./../images/disney_imagem.png');
  background-position: center;
  background-size: 95%;
  background-repeat: no-repeat;
}

.center__images-disney:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}

.center__images-salk {
  margin: 0 auto;
  height: 25rem;
  width: auto;
  transform: translateX(-3%);
  display: inline-block;
  height: 15rem;
  width: 15rem;
  overflow: hidden;
  background-image: url('./../images/salk_imagem.jpg');
  background-position: center;
  background-size: cover;
}

.center__images-salk-caixa {
  margin: 0 auto;
  display: inline-block;
  overflow: hidden;
  transition: all .2s;
  height: 15rem;
  width: 15rem;
}

.center__images-salk-caixa:hover {
  transform: translateY(-1rem);
  box-shadow: 1px 5px 5px black;
}
