.instrucoes {
  width: 85vw;
  max-width: 82rem;
  height: auto;
  margin: 0 auto;
  background-color: white;
  padding: 1.5rem;
  border-radius: 1rem;
  box-shadow: 0px .3rem .5rem black;
}

.instrucoes__opcoes {
  padding-bottom: 1.5rem;
  border-bottom: solid 1px black;
  text-transform: capitalize;
}

.instrucoes__caixa-de-texto {
  font-size: 1.6rem;
}

.instrucoes__especifico {
  background-color: orange !important;
}

.instrucoes__titulo {
  text-transform: capitalize;
}

@media(max-width: 700px) {
  .instrucoes__caixa-de-texto {
    font-size: 2rem;
  }
}

@media(max-width: 600px) {
  .instrucoes__caixa-de-texto {
    font-size: 2.4rem;
  }
}

@media(max-width: 500px) {
  .instrucoes__caixa-de-texto {
    font-size: 2.8rem;
  }
}

@media(max-width: 400px) {
  .instrucoes__caixa-de-texto {
    font-size: 3.2rem;
  }
}

@media(max-width: 385px) {
  .instrucoes__opcoes {
    text-align: center;
  }
}
